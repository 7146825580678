// ** React Imports
import { Fragment } from "react";

// ** Reactstrap Imports
import {
  Row,
  Col,
  Card,
  Label,
  Input,
  Modal,
  Button,
  CardBody,
  CardTitle,
  ModalBody,
  CardHeader,
  ModalHeader,
  FormFeedback,
} from "reactstrap";

// ** Third Party Components
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

// ** Utils
import { selectThemeColors } from "@utils";

// ** Styles
import "@styles/react/libs/react-select/_react-select.scss";
import { countries } from "../../constant/countryList";

const defaultValues = {
  name: "",
  country: "",
  address1: "",
  address2: "",
  town: "",
  province: "",
  zip_code: "",
};

const schema = yup.object({
  name: yup
    .string()
    .required("Name is Required")
    .max(50, "Name should not be greater than 50 characters")
    .trim()
    .typeError("First Name is Required")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
  country: yup.object().required("Country is a Required Field").nullable(),
  address1: yup
    .string()
    .required("Address  is Required")
    .matches(/([A-Z][a-z])/i, "Please Enter Valid Address")
    .max(100, "Address Line 1 should not have more than 100 characters")
    .typeError("Address is Required"),
  address2: yup
    .string()
    .max(100, "Address Line 2 should not have more than 100 characters")
    .typeError("Enter Address Line 2"),
  town: yup
    .string()
    .required("City Name  is Required")
    .max(60, "City Name should not have more than 60 characters")
    .matches(/([A-Z][a-z])/i, "Please Enter Valid City")
    .trim()
    .typeError("City Name is Required"),
  province: yup
    .string()
    .matches(/([A-Z][a-z])/i, "Please Enter Valid  State/Province")
    .max(60, "State/Province should not contain more than 60 characters")
    .required("State/Province is Required")
    .typeError("State/Province  is Required"),
  zip_code: yup
    .string()
    .matches(/^[a-zA-Z0-9][a-zA-Z0-9\s]+$/, "Please enter a valid zip code")
    .max(10, "zip code should not have more than 60 characters")
    .required("zip code is Required")
    .typeError("zip code  is Required"),
});

const BillingAddress = ({ handleNext }) => {
  // ** Hooks
  const {
    control,
    // setError,
    // clearErrors,
    // watch,
    // setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues, resolver: yupResolver(schema) });

  return (
    <Fragment>
      <div className=" mx-50">
        <Row
          tag="form"
          className="gy-1 gx-2"
          onSubmit={handleSubmit(handleNext)}
        >
          <Col xs={12}>
            <Label className="form-label" for="name">
              Name
            </Label>
            <Controller
              name="name"
              control={control}
              render={({ field }) => {
                return (
                  <Input
                    placeholder="John Doe"
                    id="name"
                    invalid={errors.name && true}
                    {...field}
                  />
                );
              }}
            />
            {errors?.name ? (
              <FormFeedback>{errors.name.message}</FormFeedback>
            ) : null}
          </Col>

          <Col xs={12}>
            <Label className="form-label" for="country">
              Country
            </Label>

            <Controller
              id="country"
              name="country"
              control={control}
              render={({ field: { value, ...fieldProps } }) => (
                <Select
                  {...fieldProps}
                  options={countries}
                  theme={selectThemeColors}
                  className="react-select"
                  classNamePrefix="select"
                  placeholder="Select"
                  value={countries.find((row) => row.value === value)}
                />
              )}
            />
            {errors?.country ? (
              <FormFeedback>{errors.country.message}</FormFeedback>
            ) : null}
          </Col>
          <Col xs={12}>
            <Label className="form-label" for="address1">
              Address Line 1
            </Label>

            <Controller
              name="address1"
              control={control}
              render={({ field }) => {
                return (
                  <Input
                    id="address1"
                    placeholder="12B, Business Park"
                    invalid={errors.address1 && true}
                    {...field}
                  />
                );
              }}
            />

            {errors?.address1 ? (
              <FormFeedback>{errors.address1.message}</FormFeedback>
            ) : null}
          </Col>
          <Col xs={12}>
            <Label className="form-label" for="address2">
              Address Line 2
            </Label>
            <Controller
              name="address2"
              control={control}
              render={({ field }) => {
                return (
                  <Input
                    id="address2"
                    placeholder="Mall Road"
                    invalid={errors.address2 && true}
                    {...field}
                  />
                );
              }}
            />
            {errors?.address2 ? (
              <FormFeedback>{errors.address2.message}</FormFeedback>
            ) : null}
          </Col>
          <Col xs={12}>
            <Label className="form-label" for="town">
              City
            </Label>
            <Controller
              name="town"
              control={control}
              render={({ field }) => {
                return (
                  <Input
                    id="town"
                    placeholder="Los Angeles"
                    invalid={errors.town && true}
                    {...field}
                  />
                );
              }}
            />
            {errors?.town ? (
              <FormFeedback>{errors.town.message}</FormFeedback>
            ) : null}
          </Col>
          <Col xs={12} md={6}>
            <Label className="form-label" for="province">
              State / Province
            </Label>
            <Controller
              name="province"
              control={control}
              render={({ field }) => {
                return (
                  <Input
                    id="province"
                    placeholder="California"
                    invalid={errors.province && true}
                    {...field}
                  />
                );
              }}
            />
            {errors?.province ? (
              <FormFeedback>{errors.province.message}</FormFeedback>
            ) : null}
          </Col>
          <Col xs={12} md={6}>
            <Label className="form-label" for="zip_code">
              Zip Code
            </Label>
            <Controller
              name="zip_code"
              control={control}
              render={({ field }) => {
                return (
                  <Input
                    id="zip_code"
                    invalid={errors.zip_code && true}
                    placeholder="99950"
                    maxLength={10}
                    {...field}
                  />
                );
              }}
            />
            {errors?.zip_code ? (
              <FormFeedback>{errors.zip_code.message}</FormFeedback>
            ) : null}
          </Col>
          <Col className="text-end" xs={12}>
            <Button type="submit" className=" me-1 mt-2" color="primary">
              Next
            </Button>
            {/* <Button
              type="reset"
              className="mt-2"
              color="secondary"
              outline
              onClick={onDiscard}
            >
              Discard
            </Button> */}
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default BillingAddress;
