import noAuthHttp from "./noAuthHttp";

// ** forgotPassword API

export const forgotPassword = async (value) => {
  const url = "/api/auth/forgot-password";
  const data = await noAuthHttp.post(url, { email: value });
  return data;
};

export const resetPassword = async (value) => {
  const url = "/api/auth/reset-password";
  const data = await noAuthHttp.post(url, value);
  return data;
};

export const reSendEmail = async (value) => {
  const url = `/api/auth/resend-link/${value}`;
  const data = await noAuthHttp.get(url);
  return data;
};
