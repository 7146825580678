// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";
import {
  getProductMasterTableList,
  getSingleProductList,
} from "./productMasterTableList.thunk";

const initialState = {
  allProjectList: { isFetching: false, data: [], error: null, api: 0 },
  singleProductList: { isFetching: false, data: [], error: null },
};

const ProductMasterTableListSlice = createSlice({
  name: "productMasterTableList",
  initialState,
  extraReducers: (builder) => {
    // all products
    builder.addCase(getProductMasterTableList.pending, (state) => {
      state.allProjectList.data = [];
      state.allProjectList.isFetching = true;
      state.allProjectList.api = 0;
    });

    builder.addCase(getProductMasterTableList.fulfilled, (state, action) => {
      state.allProjectList.isFetching = false;
      state.allProjectList.data = action?.payload;
      state.allProjectList.error = null;
      state.allProjectList.api = 1;
    });

    builder.addCase(getProductMasterTableList.rejected, (state, action) => {
      state.allProjectList.isFetching = false;
      state.allProjectList.error = action?.payload?.message;
      state.allProjectList.api = 1;
    });

    // single product
    builder.addCase(getSingleProductList.pending, (state) => {
      state.singleProductList.data = [];
      state.singleProductList.isFetching = true;
    });

    builder.addCase(getSingleProductList.fulfilled, (state, action) => {
      state.singleProductList.isFetching = false;
      state.singleProductList.data = action?.payload;
      state.singleProductList.error = null;
    });

    builder.addCase(getSingleProductList.rejected, (state, action) => {
      state.singleProductList.isFetching = false;
      state.singleProductList.error = action?.payload?.message;
    });
  },
});

export default ProductMasterTableListSlice.reducer;
