// ** React Imports
import { lazy } from "react";
// import EditStyleProfile from "../../views/apps/styleProfile/EditStyleProfile";
import { USER_ROUTES } from "../../constant/route";
import Pricing from "../../views/apps/pricing/Pricing";
import { ROLE } from "../../constant/constant";
import AdditionalEdit from "../../views/apps/additionalEdit/additionalEdit";
import StripeElement from "../../common/stripeComponent/StripeElement";
// import CoinStatement from "../../views/apps/coinStatement";

// const UserList = lazy(() => import("../../views/apps/user/list"));
// const UserView = lazy(() => import("../../views/apps/user/view"));

// const Roles = lazy(() => import("../../views/apps/roles-permissions/roles"));
// const Permissions = lazy(() =>
//   import("../../views/apps/roles-permissions/permissions")
// );
// Uncut Routes
const MyProjects = lazy(() => import("../../views/apps/myprojects"));
const UserProjectEdits = lazy(() =>
  import("../../views/apps/myprojects/userProjectEdits/UserProjectEdits")
);
const CreateStyleProfile = lazy(() =>
  import("../../views/apps/styleProfile/CreateStyleProfile")
);
const UserProfile = lazy(() => import("../../views/apps/userProfile"));
const Home = lazy(() => import("../../views/apps/home/index"));

const CreateProjectStepper = lazy(() =>
  import("../../views/apps/myprojects/CreateProjectStepper")
);

const SubscriptionManagement = lazy(() =>
  import("../../views/apps/Subscription/index")
);

const CaratsStatement = lazy(() => import("../../views/apps/caratsStatement"));
const EditStyleProfile = lazy(() =>
  import("../../views/apps/styleProfile/EditStyleProfile")
);
const ComingSoon = lazy(() => import("../../views/pages/misc/ComingSoon"));
const CustomIntakeForm = lazy(() =>
  import("../../views/dynamic-form-fields/CustomIntakeForm")
);

const AppRoutes = [
  // UncutGems Route
  {
    element: <Home />,
    path: USER_ROUTES.APP_HOME,
    roles: [ROLE.USER],
  },
  {
    element: <MyProjects />,
    path: USER_ROUTES.MY_PROJECTS,
    roles: [ROLE.USER],
  },
  {
    element: <UserProjectEdits />,
    path: USER_ROUTES.USER_PROJECT_EDIT,
    roles: [ROLE.USER],
  },
  {
    element: <CreateProjectStepper />,

    path: USER_ROUTES.CREATE_PROJECT,
    meta: {
      className: "create-new-project-steps",
    },
    roles: [ROLE.USER],
  },

  {
    element: <UserProfile />,
    path: USER_ROUTES.USER_PROFILE,
    roles: [ROLE.USER],
  },
  // {
  //   path: "/apps/user-profile",
  //   element: <Navigate to="/apps/user-profile/1" />,
  // },
  // {
  //   element: <UserProfile />,
  //   path: "/apps/user-profile/:id",
  // },

  {
    element: <SubscriptionManagement />,
    path: USER_ROUTES.SUBSCRIPTION,
    roles: [ROLE.USER],

    // meta: {
    //   className: "create-new-project-steps",
    // },
  },
  {
    element: <CaratsStatement />,
    path: USER_ROUTES.CARATS_STATEMENT,
    roles: [ROLE.USER],

    // meta: {
    //   className: "create-new-project-steps",
    // },
  },

  // Intake Form

  {
    element: <CreateStyleProfile />,
    path: USER_ROUTES.STYLE_PROFILE,
    roles: [ROLE.USER],
  },

  {
    element: <EditStyleProfile />,
    path: USER_ROUTES.STYLE_PROFILE_EDIT,
    roles: [ROLE.USER],
  },
  {
    element: <CustomIntakeForm />,
    path: USER_ROUTES.CUSTOM_INTAKE_FORM,
    roles: [ROLE.USER, ROLE.SUPER_ADMIN],
  },

  {
    element: <Pricing />,
    path: USER_ROUTES.PRICING,
    roles: [ROLE.USER],
  },
  {
    element: <AdditionalEdit />,
    path: USER_ROUTES.ADDITIONAL_EDIT,
    roles: [ROLE.USER],
  },
];

export default AppRoutes;
