import Breadcrumbs from "@components/breadcrumbs";
import React, { useEffect, useState } from "react";
import { Check, Edit, Plus, Trash } from "react-feather";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Table } from "reactstrap";
import { getErrorMsg } from "../../../../common/ErrorMessages/errorMessage";
import { getStatusManagementTableList } from "../../../../redux/statusManagement/statusManagementList.thunk";
import { deleteMasterStatus } from "../../../../services/statusManagement.service";
import ConfirmModel from "../../../components/modals/ConfirmModal";
import ToastContent from "../../../components/toast/toast";
import AddStatus from "./Models/AddStatus";
import LineSkelaton from "../../../../common/skeleton/LineSkelaton";
import { ROLE } from "../../../../constant/constant";

const ManageStatus = () => {
  const dispatch = useDispatch();

  const [isAddModal, setIsAddModal] = useState({ isOpen: false, data: null });

  const statusManagementTableList = useSelector(
    (state) => state?.statusManagementList?.allStatusList
  );
  const currentUser = useSelector(
    (state) => state?.currentUser?.currentuserdata?.data
  );
  const [deleteModelOpen, setDeleteModelOpen] = useState({
    isOpen: false,
    value: "",
  });

  useEffect(() => {
    dispatch(getStatusManagementTableList());
  }, []);

  const handleDeleteModal = (card) => {
    setDeleteModelOpen({ isOpen: true, value: card });
  };

  const handleDeletePaymentMethod = () => {
    // if (deleteModelOpen?.value?.deletable === "1") {
    //   deleteMasterStatus(deleteModelOpen?.value?.id)
    //     .then((res) => {
    //       toast((t) => (
    //         <ToastContent
    //           t={t.id}
    //           message={res?.message}
    //           icon={<Check size={12} />}
    //         />
    //       ));
    //       setDeleteModelOpen({ isOpen: false, value: "" });
    //       dispatch(getStatusManagementTableList());
    //     })
    //     .catch((err) => {
    //       getErrorMsg(err);
    //     });
    // } else {
    //   toast((t) => (
    //     <ToastContent
    //       t={t.id}
    //       message={"please move projects to other status then delete it"}
    //       icon={<Check size={12} />}
    //       color="danger"
    //     />
    //   ));
    //   setDeleteModelOpen({ isOpen: false, value: "" });
    // }

    deleteMasterStatus(deleteModelOpen?.value?.id)
      .then((res) => {
        toast((t) => (
          <ToastContent
            t={t.id}
            message={res?.message}
            icon={<Check size={12} />}
          />
        ));
        setDeleteModelOpen({ isOpen: false, value: "" });
        dispatch(getStatusManagementTableList());
      })
      .catch((err) => {
        getErrorMsg(err);
      });
  };

  return (
    <>
      <Breadcrumbs
        title="Work Flow"
        data={[
          { title: "Work Flow", link: "" },
          { title: "Status Management", link: "" },
        ]}
      />
      <div className="w-100 d-flex justify-content-end mb-1">
        <Button.Ripple
          color="primary"
          onClick={() => setIsAddModal({ isOpen: true, data: null })}
        >
          <Plus size={14} />
          <span className="align-middle ms-25">Add New Status</span>
        </Button.Ripple>
      </div>
      <div className="project-table">
        <Table hover>
          <thead>
            <tr>
              <th>Sr. No.</th>
              <th>Status Name</th>
              <th>Color</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {!statusManagementTableList?.isFetching &&
              statusManagementTableList?.data?.map((row, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{row?.name}</td>
                  <td>
                    <div className="d-flex">
                      <span
                        className="me-1"
                        style={{
                          backgroundColor: row.color,
                          width: "20px",
                          display: "block",
                          borderRadius: "50%",
                        }}
                      >
                        &nbsp;
                      </span>
                      <span>{row.color}</span>
                    </div>
                  </td>

                  <td>
                    <Button.Ripple
                      className="btn-icon rounded-circle me-50"
                      color="flat-success"
                      onClick={() => {
                        setIsAddModal({ isOpen: true, data: row });
                      }}
                    >
                      <Edit size={16} />
                    </Button.Ripple>
                    {row?.deletable === "1" &&
                      ROLE?.SUPER_ADMIN === currentUser?.role_id && (
                        <Button.Ripple
                          className="btn-icon rounded-circle"
                          color="flat-danger"
                          onClick={() => handleDeleteModal(row)}
                        >
                          <Trash size={16} />
                        </Button.Ripple>
                      )}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>

        <div className="no_data">
          {!statusManagementTableList?.isFetching &&
            statusManagementTableList?.data?.length === 0 && (
              <Card>
                <p>No Data found</p>
              </Card>
            )}
          {statusManagementTableList?.isFetching === true && (
            <div>
              {[...Array(5)].map((_, i) => (
                <LineSkelaton key={i} />
              ))}
            </div>
          )}
        </div>
      </div>

      {/* Add/Edit modal */}
      {isAddModal?.isOpen && (
        <AddStatus isAddModal={isAddModal} setIsAddModal={setIsAddModal} />
      )}

      {/* Delete Card */}
      <ConfirmModel
        disabled={statusManagementTableList?.isFetching}
        handleClick={handleDeletePaymentMethod}
        btnColor="danger"
        primaryBtnTitle={
          statusManagementTableList?.isFetching ? "Deleting..." : "Delete"
        }
        secondaryBtnText="Cancel"
        open={deleteModelOpen?.isOpen}
        setOpen={setDeleteModelOpen}
        info="Please ensure that there are no active projects associated with this status prior to deletion."
        title="Are you sure you want to delete this status?"
      />
    </>
  );
};

export default ManageStatus;
