/* eslint-disable no-mixed-operators */
import { dateFormat } from "../../../../common/functions/helperFunctions";
import ArchivedOrDelete from "./ArchivedOrDelete";
import AssignedEditor from "./AssignedEditor";
import { default as EstimatedCompletionDate } from "./EstimatedCompletionDate";
import FeedbackCell from "./FeedbackCell";
import ProjectStatus from "./ProjectStatus";
import { ROLE } from "../../../../constant/constant";
import { updateColumnSize } from "../../../../services/projectManagementService";
import { ADMIN_ROUTES } from "../../../../constant/route";
import { customComparator } from "../../../../utility/Utils";
import { useNavigate } from "react-router-dom";

let setTimeInstance;

export const columnDefs = () => {
  const navigate = useNavigate();
  // OLD META FOR CUSTOM TABLE
  // const data = [
  //   {
  //     headerName: "Company Name",
  //     field: "company_name",
  //     width: 200,
  //     cellClass: "cell-class",

  //     sort: null,
  //     cellRenderer: CustomCompanyNameCell,
  //   },
  //   {
  //     headerName: "Order Date",
  //     field: "order_date",
  //     width: 170,
  //     sort: null,
  //     cellClass: "cell-class",
  //     cellStyle: (params) => ({}),
  //     valueFormatter: (params) => dateFormat(params?.data?.order_date),
  //     getQuickFilterText: (params) => {
  //       const formattedDate =
  //         params?.data?.order_date !== null
  //           ? new Date(params?.data?.order_date).toLocaleDateString("en-US", {
  //               month: "2-digit",
  //               day: "2-digit",
  //               year: "2-digit",
  //             })
  //           : null;
  //       return formattedDate;
  //     },
  //   },
  //   {
  //     headerName: "Project Name",
  //     field: "project_name",
  //     width: 280,
  //     sort: null,
  //     cellRenderer: ProjectNameClick,
  //   },
  //   {
  //     headerName: "Project Type",
  //     field: "project_type",
  //     width: 475,
  //     sort: null,
  //     cellStyle: { textTransform: "capitalize" },
  //     valueFormatter: (params) =>
  //       (params.data?.project_type &&
  //         params.data.project_sub_type &&
  //         `${params.data.project_type} : ${params.data.project_sub_type}`) ||
  //       "--",
  //     getQuickFilterText: (params) => {
  //       const formattedText =
  //         params.data?.project_type &&
  //         params.data.project_sub_type &&
  //         `${params.data.project_type} : ${params.data.project_sub_type}`;
  //       return formattedText;
  //     },
  //   },
  //   {
  //     headerName: "Language",
  //     field: "wedding_language",
  //     width: 170,
  //     sort: null,
  //     cellStyle: { textTransform: "capitalize" },
  //     valueFormatter: (params) => {
  //       return params.value ? params.value : "--";
  //     },
  //   },

  //   {
  //     headerName: "NLE Software",
  //     field: "nle_software",
  //     width: 320,
  //     sort: null,
  //     valueFormatter: (params) => {
  //       return params.value === null ? "--" : params.value.replace(/_/g, " ");
  //     },
  //     cellStyle: (params) => ({
  //       textTransform: "uppercase",
  //     }),
  //     getQuickFilterText: (params) => {
  //       const formattedText =
  //         params.data?.nle_software !== null
  //           ? params.data?.nle_software.replace(/_/g, " ")
  //           : null;
  //       return formattedText;
  //     },
  //   },
  //   {
  //     headerName: "Footage",
  //     field: "tracking_number",
  //     width: 250,
  //     cellStyle: (params) => ({
  //       textTransform: "uppercase",
  //     }),
  //     sort: null,
  //     cellClass: () => {
  //       return `footage`;
  //     },
  //     cellRenderer: (params) => {
  //       const footageTransferMethod = params.data?.footage_transfer_method;
  //       if (footageTransferMethod) {
  //         if (footageTransferMethod === "online") {
  //           return (
  //             <a
  //               className="click-name"
  //               href={params.data?.online_footage_link}
  //               target="__blank"
  //             >
  //               Link
  //             </a>
  //           );
  //         } else {
  //           return (
  //             <div>
  //               <p style={{ margin: "0" }}>
  //                 {params.data?.tracking_number} &nbsp;-&nbsp;
  //                 {params.data?.shipping_service_text}
  //               </p>
  //             </div>
  //           );
  //         }
  //       } else {
  //         return "-";
  //       }
  //     },
  //   },
  //   {
  //     headerName: "Feedback",
  //     field: "number",
  //     width: 130,
  //     cellRenderer: FeedbackCell,
  //     sortable: false,
  //     cellClass: () => {
  //       return `feedback-grouping`;
  //     },
  //   },
  //   {
  //     headerName: "Estimated Completion Date",
  //     field: "estimated_date",
  //     getQuickFilterText: (params) => {
  //       const formattedDate =
  //         params.data?.estimated_date !== null
  //           ? new Date(params.data?.estimated_date).toLocaleDateString(
  //               "en-US",
  //               {
  //                 month: "2-digit",
  //                 day: "2-digit",
  //                 year: "2-digit",
  //               }
  //             )
  //           : null;
  //       return formattedDate;
  //     },
  //     minWidth: 115,
  //     sort: null,
  //     cellRenderer: EstimatedCompletionDate,
  //     cellStyle: { display: "grid", alignItems: "center" },
  //     cellClass: () => {
  //       return `estimated-completion-date`;
  //     },
  //   },

  //   {
  //     headerName: "Review Link",
  //     field: "project_review_link",
  //     width: 300,
  //     sort: null,
  //     cellRenderer: ReviewLink,
  //     cellStyle: { display: "flex" },
  //     cellClass: () => {
  //       return `review-link-custom`;
  //     },
  //     getQuickFilterText: () => null,
  //   },
  //   {
  //     headerName: "Assigned Editor",
  //     field: "editor_name",
  //     width: 260,
  //     minWidth: 200,
  //     cellRenderer: AssignedEditor,
  //     sortable: false,
  //     cellStyle: { display: "grid", alignItems: "center" },
  //   },
  //   {
  //     headerName: "Project Status",
  //     field: "status_master_id",
  //     width: 260,
  //     minWidth: 200,
  //     sortable: false,
  //     cellRenderer: ProjectStatus,
  //     cellStyle: (params) => ({
  //       display: "grid",
  //       alignItems: "center",
  //     }),
  //   },
  //   {
  //     headerName: "Archived or Delete",
  //     field: "actions_archive_delete",
  //     width: 260,
  //     minWidth: 130,
  //     sortable: false,
  //     cellRenderer: ArchivedOrDelete,
  //     cellClass: () => {
  //       return `actions-archive-delete`;
  //     },
  //   },
  // ];

  // NEW META FOR CUSTOM TABLE ----
  const data = [
    {
      headerName: "",
      field: "custom_nullable",
      colId: "custom_nullable",
      cellRenderer: (params) => (params.value ? params.value : "--"),
      // valueFormatter: (params) => {
      //   const value = params.value;
      //   if (!value) {
      //     return "--";
      //   }
      //   if (typeof value === "string") {
      //     return value;
      //   }
      //   if (typeof value === "object") {
      //     if (Array.isArray(value)) {
      //       const labels = value.map((item) => item?.label ?? "--");
      //       return labels.join(", ");
      //     } else {
      //       return value?.label ?? "--";
      //     }
      //   }
      //   return "--";
      // },
      valueFormatter: (params) => (params.value ? params.value : "--"),
    },
    {
      headerName: "Client Name",
      field: "first_name",
      comparator: customComparator,
      cellRenderer: (props) => (
        <>
          <div
            onClick={() =>
              navigate(ADMIN_ROUTES.CLIENT_DETAIL, {
                state: { id: props.data.user_id, user_id: props.data?.user_id },
              })
            }
            className="click-name"
            style={{ cursor: "pointer" }}
          >
            {props.data.first_name} {props.data.last_name}
          </div>
        </>
      ),
      getQuickFilterText: (props) =>
        `${props.data.first_name} ${props.data.last_name}`,
    },
    {
      headerName: "Order Date",
      field: "order_date",
      width: 170,
      sort: null,
      cellClass: "cell-class",
      cellStyle: (params) => ({}),
      cellRenderer: (params) => dateFormat(params?.data?.order_date),
      getQuickFilterText: (params) => {
        const formattedDate =
          params?.data?.order_date !== null
            ? new Date(params?.data?.order_date).toLocaleDateString("en-US", {
                month: "2-digit",
                day: "2-digit",
                year: "2-digit",
              })
            : null;
        return formattedDate;
      },
    },
    {
      headerName: "Service",
      field: "project_type",
      width: 475,
      sort: null,
      cellStyle: { textTransform: "capitalize" },
      cellRenderer: (params) =>
        (params.data?.project_type &&
          params.data.project_sub_type &&
          `${params.data.project_type} : ${params.data.project_sub_type}`) ||
        "--",
      getQuickFilterText: (params) => {
        const formattedText =
          params.data?.project_type &&
          params.data.project_sub_type &&
          `${params.data.project_type} : ${params.data.project_sub_type}`;
        return formattedText;
      },
    },
    {
      headerName: "Feedback",
      field: "number",
      width: 130,
      cellRenderer: FeedbackCell,
      sortable: false,
      cellClass: () => {
        return `feedback-grouping`;
      },
    },
    {
      headerName: "Estimated Completion Date",
      field: "estimated_date",
      getQuickFilterText: (params) => {
        const formattedDate =
          params.data?.estimated_date !== null
            ? new Date(params.data?.estimated_date).toLocaleDateString(
                "en-US",
                {
                  month: "2-digit",
                  day: "2-digit",
                  year: "2-digit",
                }
              )
            : null;
        return formattedDate;
      },
      minWidth: 115,
      sort: null,
      cellRenderer: EstimatedCompletionDate,
      cellStyle: { display: "grid", alignItems: "center" },
      cellClass: () => {
        return `estimated-completion-date`;
      },
    },
    {
      headerName: "Assigned Editor",
      field: "editor_name",
      width: 260,
      minWidth: 200,
      cellRenderer: AssignedEditor,
      sortable: false,
      cellStyle: { display: "grid", alignItems: "center" },
    },
    {
      headerName: "Project Status",
      field: "status_master_id",
      width: 260,
      minWidth: 200,
      sortable: false,
      cellRenderer: ProjectStatus,
      cellStyle: { display: "grid", alignItems: "center" },
    },
    {
      headerName: "Actions",
      field: "actions_archive_delete",
      width: 260,
      minWidth: 130,
      sortable: false,
      cellRenderer: ArchivedOrDelete,
      cellClass: () => {
        return `actions-archive-delete`;
      },
    },
  ];
  return data;
};

export const defaultColumnsSize = {
  sr_no: { minSize: 30, defaultSize: 180 },
  company_name: { minSize: 80, defaultSize: 200 },
  order_date: { minSize: 80, defaultSize: 170 },
  project_name: { minSize: 80, defaultSize: 280 },
  sub_order: { minSize: 80, defaultSize: 475 },
  wedding_language: { minSize: 80, defaultSize: 170 },
  nle_software: { minSize: 80, defaultSize: 320 },
  tracking_number: { minSize: 80, defaultSize: 250 },
  number: { minSize: 80, defaultSize: 130 },
  privacy_policy: { minSize: 80, defaultSize: 220 },
  estimated_date: { minSize: 80, defaultSize: 260 },
  project_review_link: { minSize: 200, defaultSize: 300 },
  editor_id: { minSize: 80, defaultSize: 260 },
  project_status: { minSize: 80, defaultSize: 260 },
  actions_archive_delete: { minSize: 120, defaultSize: 260 },
};

export const onSortChanged = (e, alignedGrids) => {
  if (setTimeInstance) {
    clearTimeout(setTimeInstance);
  }

  const sortElement = e?.api?.sortController?.getSortModel();
  const getColumns = e?.api?.getColumnDefs();

  const columx = getColumns.map((item) => {
    if (
      sortElement.length &&
      sortElement[0].colId !== item.field &&
      !item.sortable
    ) {
      return { ...item, sort: null };
    }

    return item;
  });
  const payload = {
    type: "1",
    column_sizes: JSON.stringify(columx),
  };

  setTimeInstance = setTimeout(() => {
    updateColumnSize(payload);
  }, 1000);

  if (alignedGrids) {
    alignedGrids.forEach((row) => {
      // row?.current?.api?.setColumnDefs(columx);
      row?.api?.setColumnDefs(columx);
    });
  }
};

export const tableHeight = 618;

export function calculateDynamicHeight(rowCount, allData, tableHeight) {
  // return rowCount !== null
  return rowCount > 0
    ? rowCount >= 14
      ? tableHeight
      : 65 + rowCount * 40
    : allData?.length >= 14
    ? tableHeight
    : 65 + (allData?.length || 0) * 40;
}

export function DisplayValue(data, searchText, rowCount) {
  return (!!data && searchText !== "") || (!!rowCount && searchText !== "")
    ? "none"
    : "block";
}

export const checkUserID = (role_id) => {
  return [ROLE.CUSTOMER_SUPPORT, ROLE.EDITOR].includes(role_id);
};
