import classNames from "classnames";
import React, { useEffect, useState } from "react";
// import { CheckCircle } from "react-feather";
import {
  // Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import SubscriptionManagement from "../Subscription";
// import PricingCalculatorModel from "../myprojects/createProjectSteps/PricingCalculatorModel";
// import StripeElement from "../../../common/stripeComponent/StripeElement";
import OrderButton from "../../components/orderButton/OrderButton";
import CreateProjectInfoModel from "../myprojects/myProjectsModels/CreateProjectInfoModel";
// import WPCalculator from "../../WPCalc/WPCalculator";
import { getCurrentUser } from "../../../redux/currentUserSlice/currentUser.thunk";
import { useDispatch, useSelector } from "react-redux";
import LineSkelaton from "../../../common/skeleton/LineSkelaton";
import { getProductMasterTableList } from "../../../redux/productMaster/productMasterTableList.thunk";

const Pricing = () => {
  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.currentUser);
  // const [pricingCalculator, setPricingCalculator] = useState(false);

  // const [modalOpen, setModalOpen] = useState(false);

  const allServiceData = useSelector(
    (state) => state?.productMasterTableList?.allProjectList
  );
  console.log("allServiceData: ", allServiceData);

  const [projectInfoModel, setProjectInfoModel] = useState(false);

  useEffect(() => {
    dispatch(getCurrentUser());
    dispatch(getProductMasterTableList());
  }, []);

  return (
    <div className="plans-offer">
      <div className="row justify-content-between align-items-center mb-2">
        <div className="col-12 text-start text-lg-end">
          <OrderButton
            setProjectInfoModel={setProjectInfoModel}
            projectInfoModel={projectInfoModel}
          />
        </div>
      </div>
      <>
        <h1 className="text-center my-2 mb-3 section-title">
          Pay As You Go Pricing
        </h1>
      </>
      <Container>
        {allServiceData?.isFetching === true && (
          <div>
            {[...Array(5)].map((_, i) => (
              <LineSkelaton key={i} />
            ))}
          </div>
        )}
        <Row className="pricing-card">
          <Col xs={12} md={12} lg={12} xl={12} className="m-auto">
            <Row>
              {allServiceData?.data?.map((row) => (
                <Col key={row?.id} xs={12} md={6} lg={6} xl={4}>
                  <Card
                    className={classNames(
                      "bordered shadow-none ala-carte justify-content-end "
                    )}
                  >
                    <CardHeader className="d-flex flex-column align-items-center text-break">
                      <h2>{row?.product_name}</h2>
                    </CardHeader>

                    <CardBody className="text-break d-flex flex-column align-items-center justify-content-evenly service-body">
                      {row?.sub_products?.map((subRow, index, array) => (
                        <React.Fragment
                          key={`${subRow?.id}-${subRow?.product_master_id}`}
                        >
                          <div
                            className={`d-flex flex-column align-items-center text-center ${
                              array?.length !== index + 1 && "sub-products"
                            }`}
                          >
                            <h4>{subRow?.sub_project_name}</h4>
                            <div className="annual-plan">
                              <div className="price-div">
                                <span className="price">${subRow?.amount}</span>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      ))}
                    </CardBody>
                    {currentUser.stripePublicKey && (
                      <CardFooter className="text-center pb-25">
                        <OrderButton
                          setProjectInfoModel={setProjectInfoModel}
                          projectInfoModel={projectInfoModel}
                          buttonText="Order Now"
                        />
                      </CardFooter>
                    )}
                  </Card>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>

        {/* temporary hide this subscription page ====================================================================== [BO-26] */}
        {currentUser.stripePublicKey && (
          <>
            <hr />
            <Row className="mx-0 pb-2">
              <h1 className="text-center mb-1 mt-2 section-title  fw-bolder ">
                Unlock Exclusive Savings with Our Membership Program!{" "}
              </h1>
            </Row>
          </>
        )}
      </Container>

      {/* temporary hide this subscription page ======================================================================== [BO-26] */}
      {currentUser.stripePublicKey && <SubscriptionManagement title />}

      {/* <hr />
      <div className="text-center d-flex flex-column flex-md-row justify-content-center">
        <Button.Ripple
          className="orange-btn"
          color="flat-primary"
          onClick={(e) => {
            e.preventDefault();
            setModalOpen(true);
          }}
        >
          Compare With Pay As You Go Pricing
        </Button.Ripple>
        <Button.Ripple
          className="orange-btn ms-0 ms-md-1 mt-1 mt-md-0"
          color="flat-primary"
          onClick={(e) => {
            e.preventDefault();
            setPricingCalculator(true);
          }}
        >
          Calculate the Right Package for You!
        </Button.Ripple>
      </div> */}
      {/* <hr /> */}
      {/* <StripeElement> */}
      {/* <PricingCalculatorModel
        setShow={setPricingCalculator}
        show={pricingCalculator}
      /> */}
      {/* </StripeElement> */}

      {/* <hr /> */}
      {/*
      <div className="text-center d-flex flex-column flex-md-row justify-content-center">
      <Button.Ripple
          className="orange-btn"
          color="flat-primary"
          onClick={(e) => {
            e.preventDefault();
            setModalOpen(true);
          }}
        >
          Compare With Pay As You Go Pricing
        </Button.Ripple>
        <Button.Ripple
          className="orange-btn ms-0 ms-md-1 mt-1 mt-md-0"
          color="flat-primary"
          onClick={(e) => {
            e.preventDefault();
            setPricingCalculator(true);
          }}
        >
          Calculate the Right Package for You!
        </Button.Ripple>
      </div>
      <hr /> */}

      {projectInfoModel && (
        <CreateProjectInfoModel
          show={projectInfoModel}
          setShow={setProjectInfoModel}
        />
      )}
      {/* {modalOpen && (
        <WPCalculator modalOpen={modalOpen} setModalOpen={setModalOpen} />
      )} */}
    </div>
  );
};

export default Pricing;
