// ** Icons Import
import {
  DollarSign,
  FileText,
  FolderPlus,
  Layers,
  User,
  Users,
} from "react-feather";
import { ROLE } from "../../constant/constant";
import { USER_ROUTES } from "../../constant/route";

export default [
  // {
  //   header: "Blinkovo",
  //   role: [ROLE.USER],
  // },
  {
    id: "myprojects",
    title: "Projects",
    icon: <FolderPlus size={20} />,
    navLink: USER_ROUTES.MY_PROJECTS,
    role: [ROLE.USER],
  },
  {
    id: "userprofile",
    title: "User Profile",
    icon: <Users size={20} />,
    navLink: USER_ROUTES.USER_PROFILE,
    role: [ROLE.USER],
  },
  // {
  //   id: "styleprofile",
  //   title: "Intake Form",
  //   icon: <User size={20} />,
  //   navLink: USER_ROUTES.STYLE_PROFILE,
  //   role: [ROLE.USER],
  // },
  {
    id: "coinstatement",
    title: "Dollar Statement",
    icon: <FileText size={20} />,
    navLink: USER_ROUTES.CARATS_STATEMENT,
    role: [ROLE.USER],
  },
  {
    id: "subscription",
    title: "Subscription",
    icon: <DollarSign size={20} />,
    navLink: USER_ROUTES.SUBSCRIPTION,
    role: [ROLE.USER],
  },
  {
    id: "pricing",
    title: "Pricing",
    icon: <Layers size={20} />,
    navLink: USER_ROUTES.PRICING,
    role: [ROLE.USER],
  },
];
