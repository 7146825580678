const AUTH_ROUTES = {
  // USER
  APP_LOGIN: "/login",
  APP_REGISTER: "/register",

  // ADMIN
  ADMIN_LOGIN: "/admin/login",

  // USER AND ADMIN BOTH CAN USE
  RESET_PASSWORD: "/reset-password",

  // Calculator

  CALCULATOR: "/calculator",
};

const USER_ROUTES = {
  // HOME
  APP_HOME: "/apps/home",

  // MY PROJECTS
  MY_PROJECTS: "/apps/my-projects",
  USER_PROJECT_EDIT: "/apps/my-projects/edit-project",
  CREATE_PROJECT: "/apps/my-projects/create-project",

  // USER PROFILE
  USER_PROFILE: "/apps/user-profile/",

  // Intake Form
  // STYLE_PROFILE: "/apps/intake-form",
  // STYLE_PROFILE_EDIT: "/apps/intake-form/edit",

  // SUBSCRIPTION
  SUBSCRIPTION: "/apps/subscriptions",

  // CARATS STATEMENT
  CARATS_STATEMENT: "/apps/carat-statement",

  // PRICING
  PRICING: "/apps/pricing",

  // Additional Edit

  ADDITIONAL_EDIT: "/apps/additional-edit",

  // BO-274 INTAKE FORM
  CUSTOM_INTAKE_FORM: "/apps/custom-intake-form",
};

const ADMIN_ROUTES = {
  // CLIENT MANAGEMENT
  APP_LOGIN: "/admin/login",
  CLIENT_MANAGEMENT: "/admin/client-management",
  CLIENT_DETAIL: "/admin/client-management/client-detail",
  DASHBOARD: "/admin/dashboard",

  // DISCOUNT COUPON
  DISCOUNT_COUPON: "/admin/discount-coupon",

  // Order Management
  PROJECT_MANAGEMENT: "/admin/project-management",
  ADMIN_CREATE_PROJECT: "/admin/project-management/create-project",
  PROJECT_INFO: "/admin/project-info",
  STYLE_PROFILE_VIEW: "/admin/intake-form/view",

  // SUBSCRIPTION
  SUBSCRIPTION: "/admin/subscription",
  CREATE_SUBSCRIPTION: "/admin/create-subscription",

  // pop-up
  POPUP_MANAGEMENT_PROJECT: "/admin/popup-management/new-project",
  POPUP_MANAGEMENT_ORDER: "/admin/popup-management/order-page",
  POPUP_MANAGEMENT_SUBSCRIPTION: "/admin/popup-management/subscription",
  TERMS_OF_USE: "/admin/popup-management/terms-of-use",
  PRIVACY_POLICY: "/admin/popup-management/privacy-policy",
  // pop-up

  EDITORS_MASTER: "/admin/editors-master",
  ARCHIVED_PROJECTS: "/admin/archived-projects",

  COWORKER_ADMIN: "/admin/coworkers/admin",
  COWORKER_CUSTOMER_SUPPORT: "/admin/coworkers/customer-support",

  PROFILE: "/admin/profile",

  PRODUCT_MASTER: "/admin/product-master",
  PRODUCT_MASTER_ADD_EDIT: "/admin/product-master/edit",
  MANAGE_STATUS: "/admin/manage-status",
  STRIPE_INTEGRATION: "/admin/stripe-integration",
  // Coworker
  COWORKER: "/admin/coworker",

  // BO-275 INTAKE FORM
  ADMIN_CUSTOM_INTAKE_FORM: "/admin/custom-intake-form",

  // INTAKE & ORDER FORM
  INTAKE_ORDER_FORM: "/admin/customize-form",
};

const MAINTENANCE_ROUTE = "/maintenance";
const PAGE_NOT_FOUND = "/404";

export {
  AUTH_ROUTES,
  USER_ROUTES,
  ADMIN_ROUTES,
  MAINTENANCE_ROUTE,
  PAGE_NOT_FOUND,
};
