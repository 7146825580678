/* eslint-disable multiline-ternary */
import React, { lazy, useEffect, useRef, useState } from "react";

// ** icons
import { ShoppingCart, Home, Check, X } from "react-feather";

// ** Cutome Component
import Breadcrumbs from "@components/breadcrumbs";
import Wizard from "@components/wizard";
import {
  useDispatch,
  // useSelector
} from "react-redux";
// import { checkUserPlanType } from "./utils";
// import { createProject } from "../../../../services/myProject.services";
import toast from "react-hot-toast";
import ToastContent from "../../../components/toast/toast";
import { useNavigate } from "react-router-dom";
// import { USER_ROUTES } from "../../../constant/route";
// import PaymentWizard from "../../../common/PaymentModal/PaymentWizard";
// import StripeElement from "../../../common/stripeComponent/StripeElement";
import { subscriptionDetails } from "../../../../redux/subscription";
import { getCurrentUser } from "../../../../redux/currentUserSlice/currentUser.thunk";
import { createProjectAdmin } from "../../../../services/createProjectAdmin/createProjectAdmin.service";
import { ADMIN_ROUTES } from "../../../../constant/route";
import { getIntakeOrderFormList } from "../../../../redux/IntakeOrderFormRedux/IntakeOrderFormList.thunk";

// **import component
const CreateProject = lazy(() => import("./CreateProject"));
const OrderPage = lazy(() => import("./OrderPage"));

const CreateProjectStepperAdmin = () => {
  const ref = useRef(null);
  const [applyLoading, setApplyLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [stepper, setStepper] = useState(null);
  const [projectData, setProjectData] = useState(null);

  useEffect(() => {
    dispatch(getIntakeOrderFormList());
  }, []);

  const [backbtn, setBackkbtn] = useState(false);

  const handleNext = async (data) => {
    const activeStep = stepper._currentIndex;

    if (activeStep === 0) {
      setProjectData(data);
      stepper.next();
    } else {
      try {
        setApplyLoading(true);
        const value = {
          ...projectData,
          ...data,
          user_id: projectData?.clientName ? projectData?.clientName?.id : null,
          order_meta: data?.order_meta,
        };
        delete value.clientName;
        delete value.paymentData;

        const res = await createProjectAdmin(value);

        if (res?.data?.payment_method === "no_payment_method") {
          const payload = {
            planId: 0,
            client_secret: res?.data?.client_secret,
          };
          dispatch(subscriptionDetails(payload));
          // setPaymentPopup(true);
          toast((t) => (
            <ToastContent
              t={t.id}
              message={res?.message}
              icon={<Check size={12} />}
            />
          ));
        } else if (res?.data?.redirect_to) {
          window.open(res?.data?.redirect_to, "_self");
        } else {
          toast((t) => (
            <ToastContent
              t={t.id}
              message={res?.message}
              icon={<Check size={12} />}
            />
          ));
          dispatch(getCurrentUser());
          navigate(ADMIN_ROUTES.PROJECT_MANAGEMENT);
        }
      } catch (err) {
        toast((t) => (
          <ToastContent
            t={t.id}
            message={err.response?.data?.message}
            color="danger"
            icon={<X size={12} />}
          />
        ));
      } finally {
        setApplyLoading(false);
      }
    }
  };

  const steps = [
    {
      id: "createproject",
      title: "Create New Project",
      icon: <Home size={18} />,
      content: (
        <CreateProject
          stepper={stepper}
          setBackkbtn={setBackkbtn}
          handleNext={handleNext}
          applyLoading={applyLoading}
        />
      ),
    },
    {
      id: "order",
      title: "Order",
      icon: <ShoppingCart size={18} />,
      content: (
        <OrderPage
          stepper={stepper}
          setBackkbtn={setBackkbtn}
          handleNext={handleNext}
          applyLoading={applyLoading}
        />
      ),
    },
  ];

  return (
    <>
      <div>
        <Breadcrumbs
          title="Create New Project"
          data={[
            {
              title: "Order Management",
              link: ADMIN_ROUTES.PROJECT_MANAGEMENT,
            },
            { title: "Create New Project" },
          ]}
        />
      </div>
      <Wizard
        ref={ref}
        steps={steps}
        className="create-project-steps"
        instance={(el) => setStepper(el)}
        component={backbtn && "myproject"}
        stepperState={stepper}
        setBackkbtn={setBackkbtn}
        options={{
          linear: true,
        }}
        applyLoading={applyLoading}
      />
    </>
  );
};

export default CreateProjectStepperAdmin;
